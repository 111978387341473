<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('barc_config.user_setup_selection_committee')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5">
                        <ValidationProvider name="Memo No">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region"
                            >
                            <template v-slot:label>
                                {{ $t('commitee-setup.circular_memo_no') }}
                            </template>
                                <b-form-select
                                    plain
                                    v-model="search.circular_info_id"
                                    :options="circularInfoList"
                                    id="district_id"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5">
                        <ValidationProvider name="Committee">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region"
                            >
                            <template v-slot:label>
                                {{ $t('barc_config.committee_name') }}
                            </template>
                                <b-form-select
                                    plain
                                    v-model="search.committee_id"
                                    :options="committeeList"
                                    id="district_id"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('barc_config.user_setup_selection_committee_list')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <!-- {{ data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                              <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-if="pagination.totalRows"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import Form from './Form'

import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { userSetupApproveList, userSetupApproveStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                circular_info_id: 0,
                committee_id: 0
            },
            editItemId: 0,
            rows: [],
            committeeList: []
        }
    },
    computed: {
        circularInfoList: function () {
            const circularList = this.$store.state.incentiveGrant.commonObj.circularInfoList.filter(item => item.status === 1)
            return circularList.map(item => {
                return Object.assign({}, item, { value: item.value, text: item.memo_no })
            })
        },
        formTitle () {
            return (this.editItemId !== 0) ? this.$t('barc_config.user_setup_selection_committee_edit') : this.$t('barc_config.user_setup_selection_committee_add')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('commitee-setup.circular_memo_no'), class: 'text-center' },
                { label: this.$t('barc_config.committee_name'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'memo_no' },
                    { key: 'committee_name_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'memo_no' },
                    { key: 'committee_name_en' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    watch: {
        'search.circular_info_id': function (newVal, oldVal) {
            this.committeeList = this.getCommitteeList(newVal)
        }
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(incentiveGrantServiceBaseUrl, userSetupApproveStatus, item)
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(incentiveGrantServiceBaseUrl, userSetupApproveList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const circularInfoList = this.$store.state.incentiveGrant.commonObj.circularInfoList
            const committeeList = this.$store.state.incentiveGrant.commonObj.committeeList
            return data.map(item => {
                const circularInfo = circularInfoList.find(circular => circular.value === item.circular_info_id)
                const circularInfoObj = { memo_no: circularInfo.memo_no }
                const tmpCommittee = committeeList.find(committee => committee.value === item.committee_id)
                const committeeObj = { committee_name_en: tmpCommittee !== undefined ? tmpCommittee.text_en : '', committee_name_bn: tmpCommittee !== undefined ? tmpCommittee.text_bn : '' }
                return Object.assign({}, item, circularInfoObj, committeeObj)
            })
        },
        getCommitteeList (circularInfoId) {
            return this.$store.state.incentiveGrant.commonObj.committeeList.filter(item => item.status === 1 && item.mas_circular_info_id === circularInfoId)
        }
    }
}
</script>
