<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <ValidationProvider name="Circular Memo No" vid="circular_info_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="approval_type"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('commitee-setup.circular_memo_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="userSetup.circular_info_id"
                          :options="circularInfoList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Committee Name" vid="committee_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('barc_config.committee_id')"
                          label-for="committee_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('barc_config.committee_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="userSetup.committee_id"
                          :options="committeeList"
                          id="committee_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <h3 class="text-dark" style="font-weight:700;border-bottom:1px solid #000">{{ $t('commitee-setup.committee_details') }}</h3>
                      <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px; margin-top:20px;">
                        <b-col lg="3">
                          <p class="text-dark">{{ $t('barc_config.designation') }}</p>
                        </b-col>
                        <b-col lg="3">
                          <p class="text-dark">{{ $t('barc_config.employee_name') }}</p>
                        </b-col>
                        <b-col lg="3">
                          <p class="text-dark">{{ $t('commitee-setup.roll_namel') }}</p>
                        </b-col>
                        <b-col lg="3">
                          <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                        </b-col>
                      </b-row>
                      <b-row v-for="(detail,index) in userSetup.com_user_mains_details" :key="index">
                          <b-col lg="3" sm="8">
                            <ValidationProvider name="Designation" vid="designation_id" rules="required||min_value:1">
                              <b-form-group
                                label-for="designation_id"
                                slot-scope="{ valid, errors }"
                              >
                              <b-form-select
                                plain
                                v-model="detail.designation_id"
                                :options="designationList"
                                @input="getEmployeeList(index)"
                                id="designation_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="Employee" vid="employee_id" rules="required||min_value:1">
                              <b-form-group
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                              >
                                  <b-form-select
                                      plain
                                      v-model="detail.employee_id"
                                      :options="detail.employeeList"
                                      id="district_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="Roll Name" vid="roll_id" rules="required||min_value:1">
                              <b-form-group
                                label-for="roll_id"
                                slot-scope="{ valid, errors }"
                              >
                                <b-form-select
                                  plain
                                  v-model="detail.roll_id"
                                  :options="rollList"
                                  id="roll_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="6">
                          <div>
                              <b-button v-show="index == userSetup.com_user_mains_details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line mr-1"></i></b-button>
                              <b-button v-show="index || ( !index && userSetup.com_user_mains_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl, incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { userSetupApproveStore, userSetupApproveUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      userSetup: {
        approval_type: 0,
        circular_info_id: 0,
        committee_id: 0,
        start_date: '',
        end_date: '',
        com_user_mains_details: [
          {
            designation_id: '0',
            employee_id: '0',
            roll_id: '0',
            employeeList: []
          }
        ]
      },
      approvalTypeOption: [
        { value: 1, text: this.$t('barc_config.application') },
        { value: 2, text: this.$t('barc_config.budget') }
      ],
      userList: [],
      committeeList: [],
      rollList: []
    }
  },
  created () {
    if (this.id) {
      this.getUserList()
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  computed: {
    designationList: function () {
      return this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === 4) // 4 mean org = BARC
    },
    circularInfoList: function () {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularInfoList.filter(item => item.status === 1)
      return circularList.map(item => {
        return Object.assign({}, item, { value: item.value, text: item.memo_no })
      })
    }
  },
  watch: {
    'userSetup.circular_info_id': function (newVal, oldVal) {
      this.committeeList = this.getCommitteeList(newVal)
    },
    'userSetup.committee_id': function (newVal, oldVal) {
      this.rollList = this.getRollByCommittee(newVal)
    }
  },
  methods: {
    getUserSetupApprovalCommittee () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getUserList () {
        RestApi.getData(authServiceBaseUrl, 'user/list-by-designation').then(response => {
        if (response.success) {
          const tmp = this.getUserSetupApprovalCommittee()
          const userListDetails = response.data.map(user => {
            if (this.$i18n.locale === 'bn') {
              return { value: user.user_id, text: user.name_bn }
            } else {
              return { value: user.user_id, text: user.name }
            }
          })
          const details = tmp.com_user_mains_details.map(item => {
            return Object.assign(item, { employeeList: userListDetails })
          })
          this.userSetup = Object.assign(tmp, { com_user_mains_details: details })
        }
      })
    },
    async getEmployeeList (index) {
      const tmpDetail = this.userSetup.com_user_mains_details[index]
      const designationId = tmpDetail.designation_id
      await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', { designation_id: designationId }).then(response => {
        if (response.success) {
          const designationUserList = response.data.filter(user => user.designation_id === designationId)
          const employeListItems = designationUserList.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.user_id, text: obj.name_bn }
            } else {
              return { value: obj.user_id, text: obj.name }
            }
          })
          this.userSetup.com_user_mains_details[index].employeeList = employeListItems
          const tmp = this.userSetup.com_user_mains_details
          this.userSetup.com_user_mains_details = []
          this.userSetup.com_user_mains_details = tmp
        }
      })
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.userSetup.id) {
        result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${userSetupApproveUpdate}/${this.id}`, this.userSetup)
      } else {
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, userSetupApproveStore, this.userSetup)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    add () {
      const details = {
        designation_id: '0',
        employee_id: '0',
        roll_id: '0',
        employeeList: []
      }
      const key1 = parseInt(this.userSetup.com_user_mains_details.length - 1)
      const item = this.userSetup.com_user_mains_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
        if (item[key] === '' || item[key] === 0) {
          isEmpty = false
        }
      })
      if (isEmpty === true) {
        this.userSetup.com_user_mains_details.push(details)
      }
    },
    remove (key) {
      this.userSetup.com_user_mains_details.splice(key, 1)
    },
    getUserByDesignation (designationId) {
      RestApi.getData(authServiceBaseUrl, 'user/list-by-designation').then(response => {
        if (response.success) {
          const designationData = response.data.filter(user => user.designation_id === designationId)
          this.userList = designationData.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.user_id, text: obj.name_bn }
            } else {
              return { value: obj.user_id, text: obj.name }
            }
          })
        }
      })
    },
    getCommitteeList (circularInfoId) {
      const committes = this.$store.state.incentiveGrant.commonObj.committeeList.filter(item => item.status === 1 && item.mas_circular_info_id === circularInfoId)
      return committes.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getRollByCommittee (committeeId) {
      return this.$store.state.incentiveGrant.commonObj.committeeRollList.filter(item => item.mas_app_committee_id === committeeId)
    }
  }
}
</script>
